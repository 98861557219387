/*@charset "utf-8";*/
/*@import "~antd-mobile/dist/antd-mobile.css";*/
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/**Part1：Reset***********/
* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
}

html {
    cellspacing: 0;
}

html {
    -webkit-text-size-adjust: none;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, button, textarea, p, blockquote, th, td, a, span, img {
    margin: 0;
    padding: 0;
    word-break: break-all;
    text-shadow: none;
}

fieldset, img {
    border: 0;
}

:focus {
    outline: 0 none;
}

address, caption, cite, code, dfn, strong, th, var, optgroup {
    font-style: normal;
    font-weight: normal;
}

html, body {
    list-style: none;
    font-family: 'Microsoft yahei', Arial;
}

h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal;
}

input, button, textarea, select, optgroup, option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}

input, select {
    border: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
}

abbr, acronym {
    border: 0px;
    font-variant: normal;
}

code, kbd, samp, tt {
    font-size: 100%;
}

input, button, textarea, select {
    *font-size: 100%;
}

body {
    line-height: 1.4;
}

ol, ul {
    list-style: none;
}

li {
    vertical-align: bottom;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
}

caption, th {
    text-align: left;
}

sup, sub {
    font-size: 100%;
    vertical-align: baseline;
}

ins {
    text-decoration: none;
}

:link, :visited {
    text-decoration: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: "";
    content: none;
}

img {
    border: 0 none;
    vertical-align: middle;
}

a, img {
    outline: none;
}

mark, time {
    background: none;
    display: inline;
    font-family: Tahoma, Geneva, sans-serif;
}

.clearfix:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}

.clearfix {
    display: inline-block;
}

* html .clearfix {
    height: 1%;
}

.clearfix {
    display: block;
}

a {
    cursor: pointer;
    outline: none;
    text-decoration: none; /* transition:all 0.3s ease 0s; */
}

a:hover {
    text-decoration: none; /* transition:all 0.3s ease 0s; */
}

a:focus {
    outline: none;
    -moz-outline: none;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.hc {
    clear: both;
    font-size: 0;
    height: 0;
    line-height: 0;
    overflow: hidden;
}

.hide {
    display: none;
}

i, em {
    font-style: normal;
}

p, span, div, i {
    font-family: PingFangSC-Regular, sans-serif;
}

/* jqm初始化 */
.ui-input-text {
    display: inline;
    float: left;
}

.ui-input-text, .ui-shadow-inset {
    margin: 0;
    border: none;
}

.ui-page-theme-a a {
    font-weight: normal !important;
    color: #333;
}

.ui-page-theme-a a:visited {
    color: none;
}

.ui-page-theme-a a:hover {
    color: none;
}

.ui-shadow-inset {
    box-shadow: none;
}

.ui-corner-all {
    border-radius: 0;
}

.ui-page-theme-a .ui-btn:focus, html .ui-bar-a .ui-btn:focus, html .ui-body-a .ui-btn:focus, html body .ui-group-theme-a .ui-btn:focus, html head + body .ui-btn.ui-btn-a:focus, .ui-page-theme-a .ui-focus, html .ui-bar-a .ui-focus, html .ui-body-a .ui-focus, html body .ui-group-theme-a .ui-focus, html head + body .ui-btn-a.ui-focus, html head + body .ui-body-a.ui-focus {
    box-shadow: none;
}

/* 去除iPhone中默认的input样式 */
input[type="submit"], input[type="reset"], input[type="button"], input {
    -webkit-appearance: none;
    resize: none;
}

/* 取消链接高亮  */
body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, menu, nav, section {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* 设置HTML5元素为块 */
article, aside, details, figcaption, figure, footer, header, menu, nav, section, img {
    display: block;
}

/* 图片自适应 */
img {
    max-width: 100%;
    height: auto;
    width: auto \9; /* ie8 */
    -ms-interpolation-mode: bicubic; /*为了照顾ie图片缩放失真*/
}


html {
    font-size: 40px;
}


html {
    height: 100%;
}

body {
    width: 100%;
    margin: 0 auto;
    font-weight: normal;
    text-shadow: none !important;
    background-color: #f5f5f5;
    height: 100%;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-column {
    align-items: start;
    display: flex;
    flex-direction: column;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-line-1 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-line-2 {
    display: -webkit-box;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}