body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

/* 底部弹窗 */
.dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
}

.dialog .dialog-mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
}

.dialog .dialog-main {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #dddddd;
    z-index: 100;
    transform: translate(0, 100%);
    transition: transform .4s;
}

.dialog-mask-center {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.lineB {
    border-bottom: 1px solid #EDEDED;
}

.link-btn {
    display: block;
    height: 3rem;
    line-height: 3rem;
    font-size: 0.85rem;
    text-align: center;
    color: #444;
    background-color: #fff;
}

.link-btn:active {
    background-color: #fafafa;
}

.cancel {
    color: #888 !important;
}

.marb10 {
    margin-bottom: 0.25rem;
}

.fade-in {
    -webkit-animation: fadeIn ease .3s forwards;
    animation: fadeIn ease .3s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-out {
    -webkit-animation: fadeOut ease .3s forwards;
    animation: fadeOut ease .3s forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.slide-up {
    -webkit-animation: slideUp ease .3s forwards;
    animation: slideUp ease .3s forwards;
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

.slide-down {
    -webkit-animation: slideDown ease .3s forwards;
    animation: slideDown ease .3s forwards;
}

@keyframes slideDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}

.hidden {
    display: none;
}

/*密码弹窗*/
* {
    margin: 0;
    padding: 0
}

.clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0
}

.keybord {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: none
}

.keywrap {
    width: 100%;
    display: block;
    background-color: #d2d5de
}

.keywrap li {
    list-style: none;
    display: block;
    float: left;
    width: 31.5%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 22px;
    color: #0c0c0c;
    background-color: #fff;
    margin: 0.8% 0 0.8% 1.4%;
    border-radius: 8px;
    box-sizing: border-box;
    border-bottom: 1px solid #898a8e;
    font-family: "黑体";
}

.keywrap .oneline {
    margin-top: 1.7%
}

.keywrap .lastline {
    margin-bottom: 0.7%
}

.keywrap li:nth-child(10),
.keywrap li:nth-child(12) {
    background-color: #d2d5de;
    border: none
}

.keywrap li:last-child {
    padding: 1.4%
}

.keywrap li:last-child img {
    width: 35%
}

.keywrap .active:active {
    background-color: #a9afbb;
    outline: none;
}

.pay-wrap {
    width: 100%;
    margin-top: 8rem;
}

.pay-wrap p {
    font-size: 0.85rem;
    text-align: center;
    padding-bottom: 0.7rem;
    color: #2d2d2d;
}

.line {
    width: 80%;
    border-top: 1px solid #aeaeae;
    margin: 0 auto;
    padding: 0.5rem 0;
}

.pwd-box {
    width: 80%;
    padding-left: 5px;
    position: relative;
    border: 1px solid #9f9fa0;
    border-radius: 3px;
    overflow: hidden;
    margin: 0 auto;
    box-sizing: border-box;
}

.pwd-box .pwd-input {
    width: 100%;
    height: 45px;
    color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    font-size: 18px;
    opacity: 0;
    z-index: 1;
    letter-spacing: 35px;
}

.fake-box input {
    width: 14.9%;
    height: 45px;
    border: none;
    border-right: 1px solid #e5e5e5;
    text-align: center;
    font-size: 30px;
    box-sizing: border-box;
    padding-right: 5px;
}

.fake-box input:nth-last-child(1) {
    border: none;
}

@media screen and (max-width: 319px) and (min-width: 0px) {
    .fake-box input {
        width: 14.8%;
    }
}

@media screen and (max-width: 320px) and (min-width: 280px) {
    .keywrap li {
        height: 40px;
        line-height: 40px;
    }

    .pwd-box input[type="tel"] {
        height: 40px;
    }

    .fake-box input {
        height: 40px;
    }
}

@media screen and (max-width: 751px) and (min-width: 321px) {
    .keywrap li:last-child {
        padding: 1.5%
    }

    .keywrap li:last-child img {
        width: 30%
    }
}

@media screen and (max-width: 950px) and (min-width: 750px) {
    .keywrap li:last-child {
        padding: 0.4%
    }

    .keywrap li:last-child img {
        width: 18%
    }

    .fake-box input {
        height: 60px;
    }
}

@media screen and (max-width: 2024px) and (min-width: 951px) {
    .keywrap li:last-child {
        padding: 1%
    }

    .keywrap li:last-child img {
        width: 10%
    }
}

/**绑卡提示弹框**/
.pop-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.ui-dialog {
    position: absolute;
    width: 85%;
    max-width: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    text-align: center;
    border-radius: .3rem;
    overflow: hidden;
}

.ui-dialog__hd {
    padding: 0.5rem 0.5rem 0 0.5rem;
}

.ui-dialog__title {
    font-size: 0.9rem;
}

.ui-dialog__bd {
    font-size: 0.8rem;
    color: #2d2d2d;
    text-align: left;
    padding: 0.5rem;
}

.ui-dialog__ft {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #c7c7c7;
}

.ui-dialog__btn {
    display: block;
    flex: 1;
    box-flex: 1;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    height: 2.65rem;
    line-height: 2.65rem;
    font-size: .8rem;
    color: #3cc51f;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
}

.ui-dialog__btn:first-child {
    border-right: 1px solid #c7c7c7;
}

.ui-dialog__btn_default {
    color: #999;
}

.bank-card {
    color: red;
}

/*短信验证码*/
.pop-wrap .ui-dialog .ui-dialog__bd .noteVerification {
    background-color: rgb(246, 246, 246);
    width: 85%;
    border-radius: 0.4rem;
    margin: 0.65rem auto auto;
    height: 2.5rem;
    line-height: 2.5rem;
}

.pop-wrap .ui-dialog .ui-dialog__bd .noteVerification i {
    font-size: 0.7rem;
    padding-left: 0.8rem;
    color: #447FF6;
    border-left: 1px solid #ccc;
}

.pop-wrap .ui-dialog .ui-dialog__bd .noteVerification input {
    width: 55%;
    border: none;
    background-color: rgb(246, 246, 246);
}

.pop-wrap .ui-dialog .ui-dialog__bd .noteVerification input::-webkit-input-placeholder {
    color: #ccc;
}

.pop-wrap .ui-dialog .ui-dialog__bd .noteVerification input:-moz-placeholder {
    color: #ccc;
}

.pop-wrap .ui-dialog .ui-dialog__bd .noteVerification input:-ms-input-placeholder {
    color: #ccc;
}